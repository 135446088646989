import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../hook/getToken";

const ManageStaff = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    userName: "",
    password: "",
    confirmPassword: "",
    roles: "",
  });

  const [designations, setDesignations] = useState([]);
  const token = getToken();

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/designaiton/getAllDesignations`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setDesignations(data);
        } else {
          toast.error("Failed to fetch designations. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.");
      }
    };

    fetchDesignations();
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.userName.includes(" ")) {
      toast.error("Username cannot contain spaces.");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_URL}/v1/category-manager/signup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      }
    );

    const data = await response.json();
    if (response.ok) {
      navigate("/designation-list");
      toast.success(data.message);
      setFormData({
        name: "",
        email: "",
        phone: "",
        userName: "",
        password: "",
        confirmPassword: "",
        roles: "",
      });
    } else {
      toast.error(data.message);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-6">Create Sub-admin</h1>

      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex flex-col gap-2">
          <label className="font-medium">Name:</label>
          <input
            className="border rounded-md px-4 py-1"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-medium">Email:</label>
          <input
            className="border rounded-md px-4 py-1"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-medium">Phone:</label>
          <input
            className="border rounded-md px-4 py-1"
            type="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-medium">Username:</label>
          <input
            className="border rounded-md px-4 py-1"
            type="text"
            name="userName"
            value={formData.userName}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-medium">Password:</label>
          <input
            className="border rounded-md px-4 py-1"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-medium">Confirm Password:</label>
          <input
            className="border rounded-md px-4 py-1"
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="flex flex-col gap-2">
          <label className="font-medium">Designation:</label>
          <select
            className="border rounded-md px-4 py-1"
            name="roles"
            value={formData.roles}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>
              Select Designation
            </option>
            {designations.map((designation) => (
              <option
                key={designation._id}
                value={designation.designation_short}
              >
                {designation.designation_name}
              </option>
            ))}
          </select>
        </div>

        <div className="md:col-span-2 flex justify-center">
          <button
            type="submit"
            className="py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManageStaff;
