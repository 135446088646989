import React, { useState } from "react";
import toast from "react-hot-toast";

const ChildBannerUploadModal = ({ onClose, id }) => {
  const [file, setFile] = useState(null);
  const [imageUri, setImageUri] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width > img.height) {
          setFile(selectedFile);
          setImageUri(reader.result);
        } else {
          alert("Please upload an image where the width is greater than the height.");
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!imageUri) {
      alert("Please select a file.");
      return;
    }

    const payload = {
      banner: imageUri,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/categories/banner-upload/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      toast.success("Image uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading image. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="mx-auto bg-white rounded py-5 px-10">
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <div className="flex justify-center items-center">
              <input
                className="border mt-6 p-1"
                type="file"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="flex justify-between my-5 gap-5">
            <div
              className="py-2 px-10 bg-red-400 text-white rounded-sm cursor-pointer"
              onClick={onClose}
            >
              Cancel
            </div>
            <div>
              <button
                className="py-2 px-10 bg-blue-500 text-white rounded-sm uppercase"
                type="submit"
              >
                Add Image
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChildBannerUploadModal;
