// AddSellerModal.js
import React, { useState, useEffect } from "react";
import { getToken } from "../../hook/getToken";

const AddSellerModal = ({ isOpen, onClose, title }) => {
  const [formData, setFormData] = useState({
    seller_name: "",
    cm_id: "",
    doc_link: "",
    excelFile: null,
  });

  const [cmList, setCmList] = useState([]);

  useEffect(() => {
    const token = getToken();

    fetch(`${process.env.REACT_APP_URL}/v1/category-head/getcm`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          setCmList(data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching CM list:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      excelFile: file,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getToken();

    // Prepare body data
    const body = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "excelFile") {
        if (value) {
          body.append(key, value);
        }
      } else {
        body.append(key, value);
      }
    });

    // Make the API call
    fetch(`${process.env.REACT_APP_URL}/v1/category-head/new-seller`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response data
        console.log(data);
        onClose(); // Close modal after successful submission
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        ></span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form onSubmit={handleSubmit}>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                {title}
              </h3>
              <div className="mt-2">
                <div>
                  <label
                    htmlFor="seller_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Seller Name
                  </label>
                  <input
                    type="text"
                    name="seller_name"
                    id="seller_name"
                    value={formData.seller_name}
                    onChange={handleChange}
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  />
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="cm_id"
                    className="block text-sm font-medium text-gray-700"
                  >
                    CM Name
                  </label>
                  <select
                    name="cm_id"
                    id="cm_id"
                    value={formData.cm_id}
                    onChange={handleChange}
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  >
                    <option value="">Select CM</option>
                    {cmList.map((cm) => (
                      <option key={cm._id} value={cm._id}>
                        {cm.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="doc_link"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Document Link
                  </label>
                  <input
                    type="text"
                    name="doc_link"
                    id="doc_link"
                    value={formData.doc_link}
                    onChange={handleChange}
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  />
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="excelFile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Excel File
                  </label>
                  <input
                    type="file"
                    name="excelFile"
                    id="excelFile"
                    onChange={handleFileInputChange}
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSellerModal;
