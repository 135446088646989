import React from "react";
import { Link } from "react-router-dom";

const TableRow = ({
  data,
  index,
  length,
  checkedValue,
  handleCheckboxChange,
}) => {
  const {
    _id,
    name,
    type,
    phone,
    email,
    orderCount,
    totalSpend,
    register_date,
    status,
  } = data;

  return (
    <tr className="border-b border-solid border-gray-200 bg-white hover:bg-gray-50 text-[#222222]">
      <td className="px-2 py-2 whitespace-nowrap">{index+1 || "NA"}</td>
      <td className="px-2 py-2 whitespace-nowrap underline hover:text-blue-500">
        <Link to={`/customers/${_id}`}>{name || "NA"}</Link>
      </td>
      <td className="px-2 py-2 whitespace-nowrap">{type || "NA"}</td>
      <td className="px-2 py-2 whitespace-nowrap">{phone || "NA"}</td>
      <td className="px-2 py-2 whitespace-nowrap">{email || "NA"}</td>
      <td className="px-2 py-2 whitespace-nowrap">{orderCount || "NA"}</td>
      <td className="px-2 py-2 whitespace-nowrap">{totalSpend || "NA"}</td>
      <td className="px-2 py-2 whitespace-nowrap">{register_date || "NA"}</td>
    </tr>
  );
};

export default TableRow;
