import React, { useState, useEffect } from "react";
import UtilityListModal from "./UtilityListModal";
import UtilityListRow from "./UtilityListRow";
import { Link } from "react-router-dom";

const UtilityList = () => {
  const [childModal, setChildModal] = useState(false);
  const [data, setData] = useState({ totalCount: 0, utilityList: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_URL}/v1/utility/get`, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setChildModal(false);
  };

  return (
    <div className="py-10 text-xs  pr-5">
      {childModal && (
        <UtilityListModal visible={childModal} onClose={handleClose} />
      )}
      <div>
        <div className="flex items-center py-3">
          <Link to="/dashboard">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Link>
          <p className="text-4xl">Utility List</p>
        </div>
      </div>
      <div className="flex items-center justify-end mt-5">
        <div onClick={() => setChildModal(true)} className="flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p>Add New</p>
        </div>
      </div>

      <section>
        <div className="overflow-hidden rounded-t-3xl my-5">
          <table className="table min-w-full border border-solid">
            <thead className="bg-[#00388C]">
              <tr>
                <th className="px-6 py-2 text-left text-xs font-normal text-white">
                  Utility Name
                </th>
                <th className="px-6 py-2 text-left text-xs font-normal text-white">
                  Logo
                </th>
                <th className="px-6 py-2 text-xs font-normal text-white">
                  Products Listed
                </th>
                <th className="px-6 py-2 text-left text-xs font-normal text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.utilityList.map((utility, index) => (
                <UtilityListRow
                  key={utility._id}
                  index={index}
                  utility={utility}
                />
              ))}
            </tbody>
          </table>
        </div>
      </section>
      {childModal && (
        <UtilityListModal visible={childModal} onClose={handleClose} />
      )}
    </div>
  );
};

export default UtilityList;
