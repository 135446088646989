import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Zoom from 'react-medium-image-zoom'

const InnovativeBannersModal = ({ onClose, id, banner }) => {
  const [file, setFile] = useState(null);
  const [imageUri, setImageUri] = useState("");
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    product_url: ""
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width > img.height) {
          setFile(selectedFile);
          setImageUri(reader.result);
        } else {
          alert(
            "Please upload an image where the width is greater than the height."
          );
        }
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!imageUri) {
      alert("Please select a file.");
      return;
    }

    const payload = {
      category_id: id,

      innovative_banner: {
        img_url:imageUri,
        product_url: formData.product_url
      } 
    };
    console.log("Payload>>",payload)

   
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/categories/set-innovative-banner`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      toast.success("Image uploaded successfully!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
      alert("Error uploading image. Please try again.");
    }
  };

  // Fetch products based on selected brand
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/products/get-category-product-list?categoryId=${id}&categoryType=parent`
        );
        const data = await response.json();
        setProducts(data.productsByCategory);
      } catch (error) {
        console.error("Error fetching products:", error);
        // Handle error
      }
    };

    fetchProducts();
  }, [id]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="max-w-4xl bg-white rounded  ">
        <form className="flex flex-col pt-4" onSubmit={handleSubmit}>
         
          <div className=" flex justify-center items-center">

          <Zoom>
            <img src={banner} className="w-20 h-20 rounded-full object-contain border" alt="" />
            </Zoom>
          </div>
         
          <div className="flex flex-col px-4">
            <label
              htmlFor="product_id"
              className="block text-sm font-medium text-gray-700"
            >
              Product:
            </label>
            <select
              id="product_id"
              name="product_url"
              value={formData.product_url}
              onChange={handleInputChange}
              className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
            >
              <option value="">Select Product</option>
              {products.map((product) => (
                <option key={product._id} value={product.product_url}>
                  {product.item_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-center items-center mx-4">
              <input
                className="w-full border mt-6 p-1"
                type="file"
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse mt-4">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Upload image
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InnovativeBannersModal;
