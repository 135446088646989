'use client'
import React, { useEffect, useState } from 'react'
const SelectBrand = React.memo(({ id, name, value, onChange, index, brandList }) => {

    return (
        <div className="mt-2 ">
            <label htmlFor={id} className="block mb-2 text-sm  text-gray-700 ">Select Brand For This Image </label>
            <select
                name={name}
                id={id}
                value={value}
                onChange={(e) => onChange(index, e.target.value)}
                className='appearance-none block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm'

            >
                <option value="">-- Select Brand --</option>
                {brandList?.map((brand, index) => (
                    <option key={index} value={brand._id}>{brand.brand_name}</option>
                ))}
            </select>
        </div>
    )
})


export default SelectBrand