import React, { useState } from "react";
import ViewModal from "./ViewModal";
import EditModal from "./EditModal";

const OrdersTableRow = ({ data, onDelete }) => {
  const {
    user_order_id,
    customer_number,
    customer_name,
    payment_status,
    seller_order_id,
    order_date,
    orders = [],
  } = data;

  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showMoreOrders, setShowMoreOrders] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);

  const token = localStorage.getItem("access_token");

  const handleClose = () => {
    setViewModal(false);
    setEditModal(false);
  };

  const handleDelete = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL}/v1/order/delete_order/admin/${user_order_id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.ok) {
        onDelete();
      }
      const data = await res.json();
      console.log(data);
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const toggleShowMore = () => {
    setShowMoreOrders((prev) => !prev);
  };

  // Function to fetch the invoice PDF URL
  const fetchInvoice = async () => {
    try {
      setLoadingInvoice(true); // Set loading state
      const res = await fetch(
        `${process.env.REACT_APP_URL}/v1/orders/order-invoice/${user_order_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.success) {
        const pdfUrl = data.invoice_url;
        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
      } else {
        console.error("Failed to fetch invoice");
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
    } finally {
      setLoadingInvoice(false); // Unset loading state
    }
  };

  return (
    <>
      <tr className="border-b border-gray-200 bg-white text-xs text-[#222222]">
        <td className="px-2 py-2">
          <div className="font-semibold">#{user_order_id}</div>
        </td>
        <td>
          <div className="text-xs text-gray-500">
            {new Date(order_date).toLocaleDateString()}
          </div>
          <div className="text-xs text-gray-500">
            {new Date(order_date).toLocaleTimeString()}
          </div>
        </td>
        <td className="px-2 py-2 space-y-0.5 ">
          <div>Buyer name: {customer_name}</div>
          <div className="text-xs text-gray-500">
            Contact No: {customer_number}
          </div>
          <div className="text-xs text-gray-500">
            Seller order ID: {seller_order_id}
          </div>
          <div className="my-1">
            <span
              className={`px-2 py-0.5 text-xs rounded ${
                payment_status === "paid"
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }`}
            >
              {payment_status === "paid" ? "Paid" : "Unpaid"}
            </span>
          </div>
        </td>
        <td className="px-2 py-2 flex gap-1">
          {orders.length > 0 && (
            <img
              src={orders[0].product_images}
              alt="Product"
              className="w-10 h-10 pt-1"
            />
          )}
          {orders.length > 0 && (
            <div className="space-y-0.5">
              <div className="font-semibold">{orders[0].product_name}</div>
              <div className="text-xs text-gray-500">
                SKU: {orders[0].product_sku}
              </div>
              <div className="text-xs text-gray-500">
                Quantity: {orders[0].order_qty}
              </div>
            </div>
          )}
        </td>

        <td className="px-2 py-2">
          {orders.length > 0 && (
            <div className="text-xs capitalize text-gray-500">
              {orders[0].order_status}
            </div>
          )}
        </td>
        <td className="px-2 py-2">
          {orders.length > 0 && (
            <div className="text-xs  text-gray-500">
              {orders[0].shipment_status}
            </div>
          )}
        </td>
        <td className="px-2 py-2">
          {orders.length > 0 && (
            <div className="text-xs text-gray-500">
              ₹{orders[0].coupon_discount}
            </div>
          )}
        </td>
        <td className="px-2 py-2">
          {orders.length > 0 && (
            <div className="text-xs text-gray-500">₹{orders[0].amount}</div>
          )}
        </td>
        <td className="px-2 py-2 ">
          {/* Button to view details */}
          <div className="flex items-center justify-center gap-2">
            <div
              onClick={() => setViewModal(true)}
              className="inline-block p-1 rounded bg-blue-100 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 text-blue-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-.273.68-.58 1.339-.91 1.975C19.09 17.057 15.478 20 12 20c-3.478 0-7.09-2.943-8.632-5.025A11.994 11.994 0 012.458 12z"
                />
              </svg>
            </div>
            {viewModal && (
              <ViewModal
                id={user_order_id}
                visible={viewModal}
                onClose={handleClose}
              />
            )}

            <button
              onClick={fetchInvoice}
              disabled={loadingInvoice}
              className={`p-1 rounded ${
                loadingInvoice ? "bg-gray-300" : "bg-green-100"
              } cursor-pointer`}
              title="Download Invoice"
            >
              {loadingInvoice ? (
                "Loading..."
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                  />
                </svg>
              )}
            </button>
          </div>
        </td>
      </tr>

      {showMoreOrders &&
        orders.slice(1).map((order, index) => (
          <tr key={index} className="  bg-white  text-xs text-[#222222]">
            <td colSpan={3}></td>
            <td className="px-2 py-2 flex gap-1">
              <img
                src={order.product_images}
                alt="Product"
                className="w-10 h-10"
              />
              <div>
                <div className="font-semibold">{order.product_name}</div>
                <div className="text-xs text-gray-500">
                  SKU: {order.product_sku}
                </div>
                <div className="text-xs text-gray-500">
                  Quantity: {order.order_qty}
                </div>
              </div>
            </td>
            <td className="px-2 py-2">
              <div className="text-xs text-gray-500">{order.order_status}</div>
            </td>
            <td className="px-2 py-2">
              <div className="text-xs text-gray-500">
                {order.shipment_status}
              </div>
            </td>
            <td className="px-2 py-2">
              <div className="text-xs text-gray-500">
                ₹{order.coupon_discount}
              </div>
            </td>
            <td className="px-2 py-2">
              <div className="text-xs text-gray-500">₹{order.amount}</div>
            </td>
          </tr>
        ))}

      <tr className="bg-white text-xs text-black">
        <td colSpan={12} className="text-right">
          {orders.length > 1 && (
            <button
              onClick={toggleShowMore}
              className="p-1 bg-gray-100"
              aria-expanded={showMoreOrders}
              aria-controls={`orders-${user_order_id}`}
            >
              {showMoreOrders ? "Less Info" : "More Info "}
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default OrdersTableRow;
