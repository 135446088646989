import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import TextEditor from '../blog-category/TextEditor';
const MetaTagsModal = ({ onClose, isOpen, id, metatitle, metadesc, metatags, canonicalLink, html, heading, altTag }) => {
  const [tags, setTags] = useState([metatags]);
  const [metaTitle, setMetaTitle] = useState(metatitle);
  const [metaDesc, setMetaDesc] = useState(metadesc);
  const [canonical, setCanonical] = useState(canonicalLink)
  const [htmlContent, setHtmlContent] = useState(html)
  const [h1, setH1] = useState(heading)
  const [alt, setAlt] = useState(altTag)
  useEffect(() => {
    if (metatags && metatags.length > 0) {
      setTags(metatags);
    }
  }, [metatags]);

  const handleTagsChange = (e) => {
    const tagsArray = e.target.value.split(',').map(tag => tag.trim());
    setTags(tagsArray);
  };

  const handleTitleChange = (e) => {
    setMetaTitle(e.target.value);
  };

  const handleDescChange = (e) => {
    setMetaDesc(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      categoryId: id,
      meta_title: metaTitle,
      meta_desc: metaDesc,
      meta_tags: tags,
      canonical_link: canonical,
      footer_content: htmlContent,
      h1: h1,
      banner_alt_tag: alt
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/categories/meta-tags`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        // Handle successful response
        toast.success('Meta tags added successfully');
        onClose();
      } else {
        // Handle error response
        console.error('Failed to add meta tags');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form onSubmit={handleSubmit}>
            <h1 className="text-xl font-bold px-4 py-4">Add and Edit Meta Tags</h1>
            <div className="grid grid-cols-1 gap-2">
              <div className="px-4">
                <div className="flex flex-col">
                  <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
                    Heading
                  </label>
                  <input
                    id="metaTitle"
                    type="text"
                    name="heding"
                    value={h1}
                    onChange={(e) => setH1(e.target.value)}

                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter Page heading"
                  />
                </div>
              </div>
              <div className="px-4">
                <div className="flex flex-col">
                  <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
                    Meta Title:
                  </label>
                  <input
                    id="metaTitle"
                    type="text"
                    name="metaTitle"
                    value={metaTitle}
                    onChange={handleTitleChange}

                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter meta title"
                  />
                </div>
              </div>
              <div className="px-4">
                <div className="flex flex-col">
                  <label htmlFor="metaDesc" className="block text-sm font-medium text-gray-700">
                    Meta Description:
                  </label>
                  <textarea
                    id="metaDesc"
                    type="text"
                    name="metaDesc"
                    value={metaDesc}
                    onChange={handleDescChange}

                    className="mt-1 p-1 border-gray-300 text-black block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter meta description"
                  />
                </div>
              </div>
              <div className="px-4 pb-4">
                <div className="flex flex-col">
                  <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                    Canonical Link
                  </label>
                  <input
                    id="tags"
                    type="text"
                    name="canonical"
                    value={canonical}
                    onChange={(e) => setCanonical(e.target.value)}

                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter canonical link"
                  />
                </div>
              </div>
              <div className="px-4 pb-4">
                <div className="flex flex-col">
                  <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                    Meta Tags:
                  </label>
                  <input
                    id="tags"
                    type="text"
                    name="tags"
                    value={tags.join(', ')}
                    onChange={handleTagsChange}

                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter tags separated by commas"
                  />
                </div>
              </div>
              <div className="px-4">
                <div className="flex flex-col">
                  <label htmlFor="metaTitle" className="block text-sm font-medium text-gray-700">
                    Alt Tag
                  </label>
                  <input
                    id="metaTitle"
                    type="text"
                    name="heding"
                    value={alt}
                    onChange={(e) => setAlt(e.target.value)}

                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter alt tag"
                  />
                </div>
              </div>
              <div className='p-1'>
                <TextEditor
                  htmlContent={htmlContent}
                  onChange={setHtmlContent}
                />
              </div>

            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MetaTagsModal;
