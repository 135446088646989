import React, { useEffect, useState } from "react";
import TextEditor from "../../admin-panel/blog-category/TextEditor";
import toast from "react-hot-toast";
import { ContentHead, ContentWriter } from "../../constant";
import SideBar from "../Sidebar";
import { useLocation, useParams } from "react-router";
import { getToken } from "../../hook/getToken";

const BlogEdit = () => {
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const [formData, setFormData] = useState({
    blog_category_id: "",
    title: "",
    source: "",
    image: "",
    tags: [],
    description: "",
  });

  const location = useLocation();
  const { id } = useParams();
 console.log("Blog Id >>>>",id)
  const [blogCategoryId, setBlogCategoryId] = useState("");
  const [blogContentId, setBlogContentId] = useState("");

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const accessToken = getToken();
        const response = await fetch(
          `${process.env.REACT_APP_URL}/v1/blog/single/data/${id}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blog post data");
        }
        const data = await response.json();
        const blogPostData = data.data; // Extract blog post data from response
        setTags(blogPostData.tags);
        setFormData({
          title: blogPostData.title,
          source: blogPostData.source,
          image: blogPostData.image,
          tags: blogPostData.tags,
          description: blogPostData.description,
        });
      } catch (error) {
        console.error("Error fetching blog post data:", error);
      }
    };

    fetchBlogPost();
  }, [id]);
  console.log("Formdata>>>",formData)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryId = searchParams.get("blogCategoryId");
    const contentId = searchParams.get("blogContentId");
   
    setBlogCategoryId(categoryId || "");
    setBlogContentId(contentId || "");
  }, [location.search]);

  const handleTitleChange = (event) => {
    setFormData({ ...formData, title: event.target.value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setFormData({ ...formData, image: reader.result });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSourceChange = (event) => {
    setFormData({ ...formData, source: event.target.value });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleTagInputChange = (event) => {
    setTagInput(event.target.value);
  };

  const handleAddTag = () => {
    if (tagInput.trim() !== "") {
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const accessToken = getToken();
      const status =  "active";
      const requestBody = {
        ...formData,
        blog_category_id: blogCategoryId,
        blog_content_id: blogContentId,
        tags: tags,
       status : status
      };

      let url = "";
 
      url = `${process.env.REACT_APP_URL}/v1/blog/edit/${id}`;

      const response = await fetch(url, {
        method: id ? "POST" : "POST", // Use PUT for editing existing post, POST for creating new post
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (response.ok) {
        toast.success(data.message);
      } else {
        console.error(
          id ? "Failed to edit blog post" : "Failed to create blog post"
        );
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };
  console.log(formData);

  return (
    <main className="flex ">
      <div className="sidebar bg-[#00388c] min-h-screen w-fit sticky top-0">
        <SideBar menu={ContentHead} />
      </div>
      <div className="max-w-3xl text-xs mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 my-4">
        <h1 className="text-3xl font-semibold text-left mb-4">Blog Post</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-semibold text-left text-gray-700 mb-2"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              value={formData.title}
              onChange={handleTitleChange}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              placeholder="Enter your title"
            />
          </div>
          <div className="mb-4 ">
            <label
              htmlFor="image"
              className="block text-sm   font-semibold text-left text-gray-700 mb-2"
            >
              Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
            />
            {formData.image && (
              <img
                src={formData.image}
                alt="Uploaded"
                className="mt-2 max-w-xs mx-auto"
              />
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="source"
              className="block text-sm font-semibold text-left text-gray-700 mb-2 mr-2"
            >
              Link
            </label>
            <input
              type="text"
              id="source"
              value={formData.source}
              onChange={handleSourceChange}
              className="w-full px-3 py-2 border rounded focus:outline-none focus:border-blue-500"
              placeholder="Enter the source"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="tags"
              className="block text-sm font-semibold text-left text-gray-700 mb-2"
            >
              Tags
            </label>
            <div className="flex items-center">
              <input
                type="text"
                value={tagInput}
                onChange={handleTagInputChange}
                placeholder="Type a tag"
                className="w-full px-3 py-2 border rounded mr-2 focus:outline-none focus:border-blue-500"
              />
              <button
                type="button"
                onClick={handleAddTag}
                className="bg-blue-500 text-white px-4 py-2 rounded focus:outline-none hover:bg-blue-600"
              >
                Add
              </button>
            </div>
            <div>
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="inline-block bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm font-semibold text-left mr-2 mb-2"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleRemoveTag(index)}
                    className="ml-2 text-red-500 focus:outline-none"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-semibold text-left text-gray-700 mb-2"
            >
              Description
            </label>

            {formData?.description && (
              <TextEditor
                onChange={handleDescriptionChange}
                htmlContent={formData.description}
              />
            )}
          </div>
          <div className="text-right">
     
            <button
              type="submit"
              className="bg-blue-700 text-white px-4 py-2 rounded focus:outline-none hover:bg-blue-800"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default BlogEdit;
