import React from "react";
import Zoom from "react-medium-image-zoom";

const CartTable = ({ cartItems }) => {
  const isEmptyMessage = cartItems?.length > 0 && cartItems[0].message;

  return (
    <div>
      <h1 className="text-sm font-semibold text-start">Cart</h1>
      <div className="max-w-full h-64 bg-white rounded-xl overflow-hidden">
        {isEmptyMessage ? (
          <div className="py-32 text-center text-gray-500 text-xs">
            {cartItems[0]?.message}
          </div>
        ) : (
          <div className="relative overflow-y-auto h-full">
            <table className="w-full border-collapse">
              <thead className="bg-gray-100 sticky top-0 z-10">
                <tr>
                  <th className=" border-b px-2 py-3 text-left text-xs text-gray-500 uppercase">
                    Sl No
                  </th>
                  <th className=" border-b px-2 py-3 text-left text-xs text-gray-500 uppercase">
                    Image
                  </th>
                  <th className=" border-b px-2 py-3 text-left text-xs text-gray-500 uppercase">
                    Product Id
                  </th>
                  <th className=" border-b px-2 py-3 text-left text-xs text-gray-500 uppercase">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {cartItems?.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-2 py-3 text-left text-xs">{index + 1}</td>
                    <td className="px-2 py-3 text-left text-xs">
                      <div className="flex justify-start">
                        <Zoom>
                          <img src={item.product_image} className="w-12 h-12" alt="" />
                        </Zoom>
                      </div>
                    </td>
                    <td className="px-2 py-3 text-left text-xs">
                      {item.product_id}
                    </td>
                    <td className="px-2 py-3 text-left text-xs">
                      {item.order_qty}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartTable;
