import React, { useState, useEffect } from 'react'
import BrandImageAndIdInput from './BrandImageAndIdInput'
const BrandBanner = () => {
    useEffect(() => {
        document.title = 'Add Brand Banner'
        window.scrollTo(0, 0)
    }, [])
    const [brandList, setBrandList] = useState([])
    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_URL}/v1/brand-registration/all-active-brand`;
        console.log('apiUrl', apiUrl);
        const getBrandList = async (apiUrl) => {
            const response = await fetch(apiUrl)
            const data = await response.json()
            return data.brands;

        }
        getBrandList(apiUrl).then(data => {
            setBrandList(data)
            console.log('product list', data);
        }).catch(error => console.error('Error:', error));

    }, [])
    return (
        <div>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Home Page Banner 1 </h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        brandList={brandList}
                        name='home-page-banner1'
                    />

                </div>

            </section >
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Home Page Banner 2</h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='home-page-banner2'
                        brandList={brandList}
                    />

                </div>
            </section>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Home Page Branner 3</h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='home-page-banner3'
                        brandList={brandList}
                    />

                </div>
            </section>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Home Page Branner 4</h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='home-page-banner4'
                        brandList={brandList}
                    />

                </div>
            </section>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Home Page Branner 5</h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='home-page-banner5'
                        brandList={brandList}
                    />

                </div>
            </section>
            <section>
                <div className='w-full  flex justify-between items-center py-5'>
                    <h1 className='text-2xl text-gray-900 font-semibold'>Add Work From Home Banner</h1>

                </div>
                <div className='w-full flex flex-col gap-y-5'>
                    <BrandImageAndIdInput
                        name='wfh-page-banner'
                        brandList={brandList}
                    />

                </div>
            </section>
        </div >
    )
}

export default BrandBanner