import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import AssignedStaffs from "./AssignedStaffs";
import Pagination from "../../Pagination";
import { getToken } from "../../hook/getToken";

const DesignationList = () => {
  const [staffs, setStaffs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchStaffList = async (page) => {
    const token = getToken();
    const url = `${process.env.REACT_APP_URL}/v1/category-manager/getCmList?page=${page}&limit=${pageSize}`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setStaffs(data.nonAdmins);
        setTotalCount(data.totalCount);
      }
    } catch (error) {
      console.error("Error fetching staff list:", error);
    }
  };

  useEffect(() => {
    fetchStaffList(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderStaffs = () => {
    return staffs.map((item, index) => (
      <AssignedStaffs key={item._id} data={item} index={index} />
    ));
  };

  return (
    <div className="">
      <div>
        <div className="flex items-center py-3">
          <Link to="/category">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Link>
          <p className="text-2xl">Sub-admin List</p>
        </div>
      </div>
      <div className="mb-4">
        <Link to="/manage-staff">
          <div className="flex justify-start items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p className="text-sm font-bold">Add New Staff Members</p>  
          </div>
        </Link>
      </div>

      <section>
        <div className="max-w-full relative overflow-x-auto">
          <table className="w-full text-left text-xs">
            <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
              <tr>
                <th scope="col" className="px-2 py-3">
                  Sl No
                </th>
                <th scope="col" className="px-2 py-3">
                  Employee ID
                </th>
                <th scope="col" className="px-2 py-3">
                  Name
                </th>
                <th scope="col" className="px-2 py-3">
                  Contact No
                </th>
                <th scope="col" className="px-2 py-3">
                  Email
                </th>
                <th scope="col" className="px-2 py-3">
                  Roles
                </th>
                <th scope="col" className="px-2 py-3">
                  Username
                </th>
                <th scope="col" className="px-2 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>{renderStaffs()}</tbody>
          </table>
        </div>
      </section>
      <div className="max-w-6xl mx-auto flex justify-end my-4 relative">
        <Pagination
          setCurrentPage={handlePageChange}
          totalItems={totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
        />
      </div>
    </div>
  );
};

export default DesignationList;
