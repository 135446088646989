import { useState } from "react";
import React from "react";
import AddBrandListModal from "./AddBrandListModal";

const SellerTableRow = ({ seller, index }) => {
  const [isBrandListModalOpen, setIsBrandListModalOpen] = useState(false);

  const openBrandListModal = () => {
    setIsBrandListModalOpen(true);
  };

  const closeBrandListModal = () => {
    setIsBrandListModalOpen(false);
  };
  return (
    <tr key={seller._id} className="text-center">
      <td className="px-2 py-2">{index + 1}</td>
      <td className="px-2 py-2">{seller.seller_code}</td>
      <td className="px-2 py-2">{seller.fullname}</td>
      <td className="px-2 py-2">{seller.email}</td>
      <td className="px-2 py-2">{seller.phone}</td>
      <td className="px-2 py-2">{seller.store_name}</td>
      <td className="px-2 py-2">{seller.sellerType}</td>
      <td className="px-2 py-2">{seller.isVerify}</td>
      <td className="px-2 py-2">{seller.cm_name[0]}</td>
      <tr key={seller._id} className="text-center">
        {/* Your existing table data */}
        {/* Add a View button to show brand list */}
        <td className="px-2 py-2">
          <button
            onClick={openBrandListModal}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            View
          </button>
          <AddBrandListModal
            isOpen={isBrandListModalOpen}
            onClose={closeBrandListModal}
            sellerId={seller._id}
          />
        </td>
      </tr>
    </tr>
  );
};

export default SellerTableRow;
