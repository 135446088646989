import format from "date-fns/format";
const SellerTable = ({ sellers }) => {
  console.log("Onboarded Sellers :", sellers);

  return (
    <div>
      {/* Table Section */}
      <div className="relative  overflow-hidden">
        <table className="w-full text-left text-xs">
          <thead className="bg-gray-100 text-xs font-medium uppercase text-[#666666]">
            <tr>
              <th scope="col" className="px-2 py-3">
                Onboarding Date
              </th>
              <th scope="col" className="px-2 py-3">
                Seller Name(Code)
              </th>
              <th scope="col" className="px-2 py-3">
                Business Type{" "}
              </th>
              <th scope="col" className="px-2 py-3">
                Store Name
              </th>
              <th scope="col" className="px-2 py-3">
                Email
              </th>

              <th scope="col" className="px-2 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(sellers) &&
              sellers?.map((item) => {
                const date = format(new Date(item?.createdAt), "dd/MM/yyyy");
                return (
                  <tr key={item._id}>
                    <td className="px-2 py-3">{date}</td>
                    <td className="px-2 py-3">
                      {item?.fullname}({item?.seller_code})
                    </td>
                    <td className="px-2 py-3">{item?.sellerType}</td>
                    <td className="px-2 py-3">{item?.store_name}</td>
                    <td className="px-2 py-3">{item?.email}</td>
                    <td className="px-2 py-3">
                      <a
                        href={`https://seller.21genx.com/staff-access?id=${item._id}`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SellerTable;
