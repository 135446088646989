import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

const ChildTagModal = ({ onClose, isOpen, id, childtags }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (childtags && childtags.length > 0) {
      setTags(childtags);
    }
  }, [childtags]);

  const handleTagsChange = (e) => {
    const tagsArray = e.target.value.split(',').map(tag => tag.trim());
    setTags(tagsArray);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      categoryId: id,
      category_tags: tags,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/v1/categories/category-tag`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        // Handle successful response
        toast.success('Tags added successfully');
        onClose();
      } else {
        // Handle error response
        console.error('Failed to add tags');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? 'block' : 'hidden'}`}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"></span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <form onSubmit={handleSubmit}>
            <h1 className="text-xl font-bold px-4 py-4">Add and Edit Tags</h1>
            <div className="grid grid-cols-1 gap-6">
              <div className="p-4">
                <div className="flex flex-col">
                  <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                    Tags:
                  </label>
                  <input
                    id="tags"
                    type="text"
                    name="tags"
                    value={tags.join(', ')}
                    onChange={handleTagsChange}
                    required
                    className="mt-1 p-1 border-gray-300 block w-full shadow-sm sm:text-sm border rounded-md"
                    placeholder="Enter tags separated by commas"
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChildTagModal;
