import React, { useState, useEffect } from 'react'
import ImgPreviewModal from './ImgPreviewModal';
import { getToken } from '../../hook/getToken';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const ReviewModal = ({ visible, onClose, id }) => {
    const [imgPreviewModal, setImgPreviewModal] = useState(false)
    const [productData, setProductData] = useState({});
    const [productImg, setProductImg] = useState([])
    const brandName = productData?.brand?.[0]?.brand_name ?? "Generic";
    const contryName = productData?.country?.[0]?.country_name ?? 'Null';
    const seo = productData?.category_tags;
    const utilityTag = productData?.utility_tags;
    const awards = productData?.awards;
    async function fetchData(url) {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                return data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.log(error);
            throw new Error('An error occurred');
        }
    }
    const access_token = getToken();
    async function UpdateStatus(id, body) {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`
                },
                body: JSON.stringify(body),
            };

            const response = await fetch(`${process.env.REACT_APP_URL}/v1/products/edit/${id}`, requestOptions)
            if (response.ok) {
                const data = await response.json();
                onClose()
                console.log(data);

            }
        } catch (error) {
            console.log(error);
        }
    }

    console.log('productData', productData);
    useEffect(() => {
        // fetching product infomation
        fetchData(`${process.env.REACT_APP_URL}/v1/products/get-products-list/id/?filter[_id][$eq]=${id}`)
            .then((data) => {
                console.log(data.productList[0]);
                setProductData(data.productList[0])
            })
            .catch((error) => console.log(error));
        // fetching product img
        fetchData(`${process.env.REACT_APP_URL}/v1/product-images/get/product/${id}`).then(data => setProductImg(data.image_list)).catch(error => console.log(error))
    }, [id]);

    const handleVerify = () => {
        UpdateStatus(id, { approval_status: 'approved' })
    }
    const handleDecline = () => {
        UpdateStatus(id, { approval_status: 'pending' })
    }

    const [selectedTab, setSelectedTab] = useState(1)
    const handleClick = (value) => {
        setSelectedTab(value)
        console.log(value);
    }

    const handleClose = () => {
        setImgPreviewModal(false)
    }
    const handleOutSide = (e) => {
        if (e.target.id === 'container') {
            onClose()
        }
    }

    if (visible)
        return (
            <div
                id='container'
                onClick={(e) => handleOutSide(e)}
                className='fixed  z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
                <div className='w-11/12 mx-auto flex flex-col bg-white rounded-lg shadow-lg p-4'>
                    <div className='flex gap-x-10'>
                        <div
                            onClick={() => setImgPreviewModal(true)}
                            className=''>
                            <Zoom><img className='w-44 aspect-square'
                                src={productImg?.main_img} alt='product-img' /></Zoom>
                        </div>
                        <div className='flex grow flex-col my-auto'>
                            <div className='flex gap-x-5 py-1'>
                                <h3 className='w-1/5 text-right'>Name:</h3>
                                <p>{productData?.item_name}</p>
                            </div>
                            <div className='flex gap-x-5 py-1'>
                                <h3 className='w-1/5 text-right'>Brand:</h3>
                                <p>{brandName}</p>
                            </div>
                            <div className='flex gap-x-5 py-1'>
                                <h3 className='w-1/5 text-right'>Modal Number:</h3>
                                <p>{productData?.model_number_vital_info
                                }</p>
                            </div>
                            <div className='flex gap-x-5 py-1'>
                                <h3 className='w-1/5 text-right'>Status:</h3>
                                <p>{productData?.status}</p>
                            </div>
                            {
                                productData?.createdAt &&
                                <div className='flex gap-x-5 py-1'>
                                    <h3 className='w-1/5 text-right'>Available From:</h3>
                                    <p>{productData?.createdAt}</p>
                                </div>
                            }
                            {
                                productData?.updatedAt &&
                                <div className='flex gap-x-5 py-1'>
                                    <h3 className='w-1/5 text-right'>Last Update:</h3>
                                    <p>{productData?.updatedAt}</p>
                                </div>
                            }
                        </div>
                    </div>
                    {/* Info section */}
                    <div className='flex justify-around my-5 border-b boder-solid border-gray-900'>
                        <div
                            onClick={() => handleClick(1)}
                            className={`text-sm text-gray-600 uppercase py-2 px-9 cursor-pointer rounded ${selectedTab === 1 ? 'shadow-[0px_4px_4px_rgba(0,0,0,0.25)] bg-[#F0F0F0]' : ''}`}>
                            Basic Info
                        </div>
                        <div
                            onClick={() => handleClick(2)}
                            className={`text-sm text-gray-600 uppercase py-2 px-9 cursor-pointer rounded ${selectedTab === 2 ? 'shadow-[0px_4px_4px_rgba(0,0,0,0.25)] bg-[#F0F0F0]' : ''}`}>
                            Description
                        </div>
                        <div
                            onClick={() => handleClick(3)}
                            className={`text-sm text-gray-600 uppercase py-2 px-9 cursor-pointer rounded ${selectedTab === 3 ? 'shadow-[0px_4px_4px_rgba(0,0,0,0.25)] bg-[#F0F0F0]' : ''}`}>
                            Details
                        </div>
                        <div
                            onClick={() => handleClick(4)}
                            className={`text-sm text-gray-600 uppercase py-2 px-9 cursor-pointer rounded ${selectedTab === 4 ? 'shadow-[0px_4px_4px_rgba(0,0,0,0.25)] bg-[#F0F0F0]' : ''}`}>
                            Seo
                        </div>
                        <div
                            onClick={() => handleClick(5)}
                            className={`text-sm text-gray-600 uppercase py-2 px-9 cursor-pointer rounded ${selectedTab === 5 ? 'shadow-[0px_4px_4px_rgba(0,0,0,0.25)] bg-[#F0F0F0]' : ''}`}>
                            Award
                        </div>
                    </div>
                    <div className='min-h-20'>
                        {
                            selectedTab === 1 &&
                            <div className='w-1/2 flex grow flex-col'>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Manufacturer:</h3>
                                    <p>{productData?.manufacturer_vital_info}</p>
                                </div>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Origin country:</h3>
                                    <p>{contryName}</p>
                                </div>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>ITF:</h3>
                                    <p>{productData?.item_form_variant_attributes
                                    }</p>
                                </div>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>MPN:</h3>
                                    <p>{productData?.manufacturer_part_number_vital_info}</p>
                                </div>
                            </div>
                        }
                        {
                            selectedTab === 2 &&
                            <div className='w-1/2 flex grow flex-col'>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Description:</h3>
                                    <p>{productData?.product_description}</p>
                                    <p>{productData?.bullet_point}</p>
                                </div>
                            </div>
                        }
                        {
                            selectedTab === 3 &&
                            <div className='w-1/2 flex grow flex-col'>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Item Condition:</h3>
                                    <p>{productData?.item_condition}</p>
                                </div>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Release Date:</h3>
                                    <p>{productData?.release_date_more_details}</p>
                                </div>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Sku:</h3>
                                    <p>{productData?.product_sku}</p>
                                </div>
                                <div className='flex gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Modal Year:</h3>
                                    <p>{productData?.model_year_more_details}</p>
                                </div>

                            </div>
                        }
                        {
                            selectedTab === 4 &&
                            <div className='w-1/2 flex grow flex-col'>
                                <div className='flex  gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Category Tags</h3>
                                    <div className='flex gap-x-5 gap-y-2 flex-wrap'>
                                        {Array.isArray(seo) &&
                                            seo.map((tag) => (
                                                <p>{tag}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='flex  gap-x-5 py-1 border-y'>
                                    <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>Utility Tag</h3>
                                    <div className='flex gap-x-5 gap-y-2 flex-wrap'>
                                        {Array.isArray(utilityTag) &&
                                            utilityTag.map((tag) => (
                                                <p>{tag}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            selectedTab === 5 &&
                            <div className='w-1/2 flex grow flex-col'>
                                {Array.isArray(awards) &&
                                    awards.map((award) => (
                                        <div className='flex gap-x-5 py-1 border-y'>
                                            <h3 className='w-32 shrink-0 text-[#737373] font-bold text-right'>{award?.award_name}:</h3>
                                            <p>{award?.award_description}</p>
                                            <div className='w-10 h-10 shrink-0'>
                                                <Zoom><img className='w-full h-full object-cover rounded-full' src={award?.image} /></Zoom>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        }
                    </div>
                    <div className='flex justify-center gap-x-5 py-5'>
                        <button
                            onClick={handleVerify}
                            className='flex justify-center items-center py-2 px-4 bg-[#28A745] text-white' type='button'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>

                            Verify
                        </button>
                        <button
                            onClick={handleDecline}
                            className='flex justify-center items-center py-2 px-4 bg-[#DC3545] text-white' type='button'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>

                            Decline
                        </button>

                    </div>
                </div>
                {
                    imgPreviewModal && <ImgPreviewModal
                        visible={imgPreviewModal}
                        images={productImg}
                        onClose={handleClose}
                    />
                }
            </div>
        )
}

export default ReviewModal