import React, { useState, useEffect } from 'react'
import TableRow from './TableRow'
import { Link } from 'react-router-dom'
import { User } from '../user/User'
import { getToken } from '../../hook/getToken'
import Pagination from '../../Pagination'

const Customers = () => {
    const [customerData, setCustomerData] = useState([])
    const [countData, setCountData] = useState({})
    const [filterValue, setFilterValue] = useState('desc')
    const [searchTerm, setSearchTerm] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const url = `${process.env.REACT_APP_URL}/v1/customer/getCustomer?page=${currentPage}&limit=${pageSize}&sort=${filterValue}&search=${searchTerm}`;

            const token = getToken(); // Replace with your actual Bearer token

            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            try {
                const response = await fetch(url, requestOptions);
                const data = await response.json();

                console.log('customer-data', data);

                setCountData({
                    "b2cCount": data.b2cCount,
                    "b2bCount": data.b2bCount,
                    "totalActiveCount": data.totalActiveCount
                });

                setCustomerData(data.allCustomers);
                setTotalItems(data.b2cCount)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [filterValue, currentPage, pageSize, searchTerm]);

    const handleFilterChange = (e) => {
        console.log('value', e.target.value);
        setFilterValue(e.target.value)
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value));
        setCurrentPage(1); // Reset to the first page when page size changes
    }

    const [checkedValue, setCheckedValue] = useState([]);

    const handleCheckboxChange = (id) => {
        // Check if the id is already in the array
        const index = checkedValue.indexOf(id);

        if (index === -1) {
            // If not in array, add it
            setCheckedValue([...checkedValue, id]);
        } else {
            // If already in array, remove it
            const updatedArray = checkedValue.filter(item => item !== id);
            setCheckedValue(updatedArray);
        }
    };

    return (
        <main>
            <div className='container mx-auto flex flex-col pr-5'>
                <section>
                    <div className='max-w-6xl mx-auto flex justify-between py-5'>
                        <Link to='/customers' className='text-2xl text-gray-900 font-semibold'>Customers</Link>
                        <User />
                    </div>
                </section>

                <section>
                    <div className='max-w-6xl mx-auto flex flex-col'>
                        <div className='flex justify-between px-10 py-5'>
                            <div>
                                <p className='text-2xl font-bold'>All Active Customers</p>
                            </div>
                            <div className='flex gap-5'>
                                <form className="flex items-center">
                                    <div className="flex items-center px-2 py-1 gap-x-1 bg-gray-100 rounded-2xl ">
                                        <div className="p-1">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-4 h-4 text-gray-600 font-bold"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            className="w-52 py-1 px-1 bg-gray-100 outline-0"
                                            value={searchTerm}
                                            onChange={handleSearch}
                                            type="text"
                                        />
                                    </div>
                                </form>
                                <select
                                    name='filter-asc-desc'
                                    onChange={(e) => handleFilterChange(e)}
                                    className='flex items-center px-2 py-1 gap-x-1 bg-gray-100 rounded-2xl'
                                >
                                    <option value='desc'>Newest</option>
                                    <option value='asc'>Oldest</option>
                                </select>
                                <select
                                    name='page-size'
                                    onChange={(e) => handlePageSizeChange(e)}
                                    className='flex items-center px-2 py-1 gap-x-1 text-xs bg-gray-100 rounded-2xl'
                                >
                                    <option className='text-xs' value={10}>10</option>
                                    <option className='text-xs' value={50}>50</option>
                                    <option className='text-xs' value={100}>100</option>
                                    <option className='text-xs' value={200}>200</option>
                                </select>
                            </div>
                        </div>
                        <div className='flex justify-end'>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="max-w-screen-2xl relative overflow-x-scroll">
                        <table className="w-full text-left text-xs">
                            <thead className="bg-white text-xs font-normal text-gray-400">
                                <tr>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Sl No</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Customer Name</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Type</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Phone Number</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Email</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Orders</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Total Spend</th>
                                    <th scope="col" className="px-2 py-3 whitespace-nowrap ">Date Registered</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    customerData?.length ?
                                        customerData.map((item, index) => (
                                            <TableRow
                                                key={item._id + index}
                                                data={item}
                                                index={index}
                                                length={customerData.length}
                                                checkedValue={checkedValue}
                                                handleCheckboxChange={handleCheckboxChange}
                                            />
                                        ))
                                        : <tr><td colSpan="9" className="text-center py-5">No customers found</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='flex justify-end items-center py-5'>
                        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems}
                            pageSize={pageSize}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Customers
