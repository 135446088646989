import React from "react";
import Zoom from "react-medium-image-zoom";

const OrdersTableRow = ({ data }) => {
  const {
    order_status = "", // Default to an empty string if undefined
    shipment_status = "",
    user_order_id,
    total_amount,
    order_date,
    product_image,
  } = data;

  // Function to format the order date to dd/mm/yy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  return (
    <tr className="border-b border-solid border-gray-200 bg-white hover:bg-gray-50 text-[#222222]">
      <td
        scope="row"
        className="whitespace-nowrap px-2 py-2 font-medium text-gray-900 "
      >
        <Zoom>
          <img src={product_image} alt="" className="w-12 h-12" />
        </Zoom>
      </td>
      <td className="px-2 py-2">{user_order_id}</td>
      <td className="px-2 py-2">{formatDate(order_date)}</td>
      <td className="px-2 py-2">₹{total_amount}</td>
      <td className="px-2 py-2 capitalize">
        {shipment_status ? shipment_status.replace(/_/g, " ") : "N/A"}
      </td>
    </tr>
  );
};

export default OrdersTableRow;
