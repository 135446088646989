import React, { useEffect, useState } from "react";
import OrdersTableRow from "../orders/OrdersTableRow";
import { User } from "../user/User";
import Pagination from "../../Pagination";
import { getToken } from "../../hook/getToken";

const status = [
  { fieldName: "order_status", name: "cancelled" },
  { fieldName: "order_status", name: "processing" },
  { fieldName: "order_status", name: "delivered" },
  { fieldName: "order_status", name: "shipped" },
  { fieldName: "payment_status", name: "paid" },
  { fieldName: "payment_status", name: "due" },
];

const OrdersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [dateOption, setDateOption] = useState(""); // State for date sorting
  const [amountOption, setAmountOption] = useState(""); // State for amount sorting
  const [filterValues, setFilterValues] = useState({
    order_status: [],
    payment_status: [],
    start_date: "",
    end_date: "",
    sort_amount: "",
    sort_date: "",
  });
  const [orderList, setOrderList] = useState([]);

  const handleDelete = () => setIsClicked((prev) => !prev);

  async function fetchOrderData() {
    const token = getToken(); // Get token if required
    const {
      order_status,
      payment_status,
      start_date,
      end_date,
      sort_amount,
      sort_date,
    } = filterValues;
    const query = new URLSearchParams({
      page: currentPage,
      limit: pageSize,
      order_status: order_status.join(","),
      payment_status: payment_status.join(","),
      start_date,
      end_date,
      sort_amount,
      sort_date,
    }).toString();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/v1/order/get-orders?page=1&limit=5&${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      setOrderList(data.data);
      setTotalItems(data.totalCount);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchOrderData();
  }, [isClicked, filterValues, currentPage, pageSize]);

  return (
    <main>
      <div className=" text-xs border mt-2">
        <section>
          <div className="relative overflow-x-auto">
            <table className="w-full text-left text-xs">
              <thead className="bg-gray-100 text-xs uppercase text-[#666666]">
                <tr>
                  <th scope="col" className="px-2 py-3">
                    Order Id
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Order Date
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Order Details
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Product Details
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Shipping
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Discount
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Actions
                  </th>
                  <th scope="col" className="px-2 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {orderList.map((item) => (
                  <OrdersTableRow
                    key={item._id}
                    data={item}
                    onDelete={handleDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </main>
  );
};

export default OrdersTable;
